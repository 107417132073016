<template>
  <div id="wrap">
    <Header :title="pageTitle"></Header>
    <div class="formJoin">
      <div class="formSubmit">
        <h1 class="title">加入/创建企业</h1>
        <div class="formUpdate">
          <el-form ref="loginForm" :model="formData">
            <el-form-item>
              <el-input v-model="formData.inviteCode" type="text" placeholder="请输入企业邀请码"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="loginAction clearfix">
          <el-button type="primary " class="action fl" plain @click="createOne">创建企业</el-button>
          <el-button type="primary " class="action fr" @click="joinOne">加入企业</el-button>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import Header from "@/views/login/components/Header";
import {addEnterPrise} from "@/utils/api/company";
export default {
  name: "Info",
  components:{
    Header
  },
  data(){
    return{
      pageTitle:"企业信息",
      formData:{
        inviteCode:"",//邀请码
      }
    }
  },
  methods:{
    createOne(){
      this.$router.push('/create');
    },
    joinOne(){
      if (this.formData.inviteCode==""){
        this.$baseMessage('请输入邀请码','warning')
        return false
      }
      addEnterPrise(this.formData).then(res=>{
        if(res.code==0){
          this.$baseMessage("加入成功",'success')
          setTimeout(()=>{
            this.$router.push({'name': '控制台'})
          }, 1000)
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    }
  }
}
</script>

<style  lang="less">
body{
  background: #FAFAFA;
  height: 100%;
}
.wrap{
  width: 100%;
  height: 100%;
  background: #FAFAFA;
}

.formJoin{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 60px;
  .formSubmit{
    width: 470px;
    margin: 0 auto;
    .title{
      height: 59px;
      font-size: 42px;
      font-weight: 600;
      color: #333333;
      line-height: 59px;
      letter-spacing: 5px;
      padding-bottom: 80px;
    }
    .formUpdate{
      .el-form{
        .el-form-item{
          .el-form-item__content{
            font-size: 16px;
            line-height: 60px;
            .el-input__inner{
              height: 60px;
              line-height: 60px;
              background: #FFFFFF;
              border-radius: 2px;
              border: none;
            }
          }
        }
      }
    }
    .loginAction{
      margin: 100px 0 20px;
      button{
        width: 45%;
        height: 64px;
        background: #3C6CFC;
        border-radius: 2px;
        span{
          font-size: 24px;
          font-weight: 500;
          color: #FFFFFF;
          letter-spacing: 1px;
        }
      }
      .el-button--primary.is-plain{
        height: 64px;
        border: 1px solid #3C6CFC;
        background: inherit;
        span{
          font-size: 24px;
          font-weight: 500;
          color: #3C6CFC;
          letter-spacing: 1px;
        }
      }
    }

  }

}

</style>
