<template>
  <div class="wrap">
    <div class="navigation">
      <el-scrollbar class="side-bar-container">
        <logo></logo>
        <!--        <el-menu-->
        <!--            mode="vertical"-->
        <!--        >-->
        <!--        </el-menu>-->
        <side-bar></side-bar>
      </el-scrollbar>
    </div>
    <div class="main-body">
      <div class="fix-header">
        <div class="nav-bar-container">
          <el-row :gutter="15">
            <el-col :xs="4" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="left-panel">
                <h3 class>{{this.$store.state.company}}</h3>
              </div>
            </el-col>
            <el-col :xs="20" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="right-panel">
                <span class="word">{{this.$store.state.identity}}</span>
                <span class="word">{{this.$store.state.userInfo.realName}}</span>
                <span class="word">欢迎您！</span>
                <el-dropdown @command="handleCommand">
                  <span class="avatar-dropdown">
                    <img class="user-avatar" :src="baseUrl + (this.$store.state.userInfo.avatar? this.$store.state.userInfo.avatar: '/dict/defaultAvatar.png')" alt />
                  </span>
                  <el-dropdown-menu slot="dropdown" >
                    <el-dropdown-item command="personal" @click="handleCommand('personal')">个人中心</el-dropdown-item>
                    <el-dropdown-item command="logout" @click="handleCommand('logout')">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="app-main-container">
        <router-view :key="key" class="app-main-height" />
        <footer class="footer-copyright">
          上海伴橙科技有限公司
          <span>|</span>沪ICP备2021023371号-1
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/views/layout/compoents/logo";
import SideBar from "@/components/SideBar/index";
import { getImgUrl,getDict } from "@/utils/api/dict";
import {getUserInfo} from "@/utils/api/own";
import {loginOut} from "@/utils/api/login";
import {getList } from "@/utils/api/author";
import { getStore, setStore} from "@/utils/storage";
export default {
  name: "layout",
  components: {
    logo,
    SideBar
  },
  data() {
    return {
      company: "企业的名字",
      identity: "超级管理员",
      username: "用户名",
      avatar: require("../../img/header.png"),
      baseUrl: getStore('imgBase'),
      ownData: {}
    };
  },
  computed: {
    key() {
      return this.$route.path;
    }
  },
  created() {
    if (!getStore('imgBase')){
      this.getUrl()
    }
    this.getUserInfo()
    this.getPList()
    //防止vuex数据消失重新拉取
    this.$store.dispatch('getUserStoreInfo')

  },
  methods: {
    getUrl() {
      getImgUrl().then(res => {
        setStore("imgBase", res.data);
      });
    },
    handleCommand(command) {
      switch (command) {
        case "logout":
          console.log("退出");
          // removeStore("access_token")
          // removeStore("companyId")
            loginOut().then(res=>{
              if (res.code==0){
                localStorage.clear()
                this.$message.success('退出成功');
                setTimeout(()=>{
                  this.$router.push({name: '登陆'})
                }, 1000)
              }else{
                this.$message.warning(res.msg)
              }

            })
          break;
        case "personal":
          this.$router.push({path: '/own/changeInfo'})
          console.log("前往个人中心");
          break;
      }
    },
    //获取用户信息
    getUserInfo(){
      getUserInfo().then(res=>{
        setStore('permissions',res.data.permissions)
        let baseUrl = getStore('imgBase')
        let user = res.data.sysUser
        let roles = res.data.roleList
        // if (user.realName != null) {
        //   this.username = user.realName;
        // }
        if (user.avatar != null || user.avatar != "null") {
          this.avatar = baseUrl + user.avatar;
        }
        this.company= user.enterpriseName
        this.identity= roles[0]['roleName']
      })
    },
    //获取字典数据
    getDict(){
      let data = {
        code: 'CLLX'
      }
      getDict(data).then(res=>{
        let data = res.data
      })
    },
    getPList(){
      getList().then(res=>{
        let identity= res.data.roleList
        setStore('identity',identity)
      })
    },
  }
};
</script>

<style  lang="less">
body {
  background-color: #fafafa;
}
.side-bar-container {
  width: 250px;
  height: 100vh;
  background: #0f1f3e;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  overflow: hidden;
  transition: width 0.3s;
}
.main-body {
  padding-top: 80px;
  margin-left: 250px;
  .fix-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 997;
    width: 100%;
    overflow: hidden;
    left: 256px;
    width: calc(100% - 250px);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      background 0s, color 0s, font-size 0s;
    background-color: #fafafa;
    .nav-bar-container {
      border-bottom: 1px solid #f1f1f1;
      margin-left: calc(30px - 7.5px);
      margin-right: calc(100px - 7.5px);
      .left-panel {
        height: 80px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
      .right-panel {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: flex-end;
        height: 80px;
        .word {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          margin-left: 10px;
        }
        .avatar-dropdown {
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          justify-items: center;
          height: 80px;
          padding: 0;
          .user-avatar {
            width: 50px;
            height: 50px;
            cursor: pointer;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .app-main-container {
    margin: auto;
    margin-left: 30px;
    margin-right: 100px;

    .app-main-height {
      min-height: calc(100vh - 158px);
    }
    .footer-copyright {
      text-align: center;
      height: 77px;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      line-height: 77px;
      span {
        margin: 0 20px;
      }
    }
  }
}
</style>
