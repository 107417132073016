<template>
  <div id="wrap">
    <Header :title="pageName"></Header>
    <div class="formCreate">
      <div class="formSubmit">
        <h1 class="title">创建企业&#12288;<span class="blue" @click="toJoin"> 加入企业</span></h1>
        <div class="formUpdate">
          <div class="img">
            <el-form :model="formData" :rules="ruleCompany" ref="createForm" label-width="150px" :label-position="labelPosition2">
              <el-row align="middle" :gutter="10">
                <el-col :span="4">
                  <el-form-item label="营业执照" prop="businessLicenseUrl">
                    <img-upload
                        ref="upload"
                        img-name="营业执照"
                        :value="formData.businessLicenseUrl"
                        @input="getImg1"
                        >
                    </img-upload>

                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="授权委托书" prop="proxyStatementUrl">
                    <img-upload
                        ref="upload"
                        img-name="授权委托书"
                        :value="formData.proxyStatementUrl"
                        @input="getImg2"
                    >
                    </img-upload>

                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="道路运输许可证" prop="transportationLicenseUrl">
                    <img-upload
                        ref="upload"
                        img-name="道路运输许可证"
                        :value="formData.transportationLicenseUrl"
                        @input="getImg3"
                    >
                    </img-upload>

                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="出租车运营资格证" prop="taxiOperationLicenseUrl">
                    <img-upload
                        ref="upload"
                        img-name="出租车运营资格证"
                        :value="formData.taxiOperationLicenseUrl"
                        @input="getImg4"
                    >
                    </img-upload>

                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="公司LOGO" prop="logoUrl">
                    <img-upload
                        ref="upload"
                        img-name="公司LOGO"
                        :value="formData.logoUrl"
                        @input="getImg5"
                    >
                    </img-upload>

                  </el-form-item>
                </el-col>
              </el-row>
              <el-row align="middle" :gutter="10">
                <el-form-item label="公司环境图" prop="enterprisePhoto">
                  <el-col :span="4" v-for="(item,i) in 3">
                    <img-upload
                        ref="upload"
                        img-name="公司环境图"
                        :value="formData.enterprisePhoto[i]"
                        @input="getImg6"
                    >
                    </img-upload>

                  </el-col>

                </el-form-item>
              </el-row>
            </el-form>
          </div>

          <div class="words">
            <el-form :model="formData" :rules="ruleCompany" ref="createForm" label-width="150px" :label-position="labelPosition">
              <el-row align="middle" :gutter="10">
                <el-col :span="8">
                  <el-form-item label="企业全称" prop="enterpriseName">
                    <el-input v-model="formData.enterpriseName" type="text" placeholder="请输入企业全称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="所在地区" prop="enterpriseLocation">
<!--                    <el-input v-model="formData.enterpriseLocation" type="text" placeholder="请输入企业全称"></el-input>-->
                    <el-cascader
                        v-model="formData.enterpriseLocation"
                        :options="cityList"
                        placeholder="请选择所在地址"
                        :props="{ value: 'id',label:'text'}"
                        @change="handleChange">
                    </el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="详细地址" prop="enterpriseAddress">
                    <el-input v-model="formData.enterpriseAddress" type="text"
                              placeholder="请输入企业详细地址"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="车辆规模" prop="vehicleScale">
                    <el-select v-model="formData.vehicleScale" placeholder="请选择车辆规模企业全称">
                      <el-option
                          v-for="item in carGmList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系方式" prop="enterprisePhone">
                    <el-input v-model="formData.enterprisePhone" type="text" placeholder="请输入企业联系方式"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="统一社会信用代码" prop="socialCreditCode">
                    <el-input v-model="formData.socialCreditCode" type="text" placeholder="请输入企业统一信用代码"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="企业类型" prop="enterpriseType">
                    <el-radio-group v-model="formData.enterpriseType">
                      <el-radio label="出租车">出租车</el-radio>
                      <el-radio label="租赁公司">租赁公司</el-radio>
                      <el-radio label="企业招募">企业招募</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>

            </el-form>

          </div>

        </div>
        <div class="loginAction clearfix">
          <el-button  class="action" plain size="small" @click="resetForm">取消</el-button>
          <el-button type="primary " class="action" size="small" @click="submitForm">提交</el-button>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import Header from "@/views/login/components/Header";
import ImgUpload from "@/views/login/components/ImgUpload";
import {getTree} from "@/utils/api/tool";
import {addEnter} from "@/utils/api/company";
import {ocr} from "@/utils/api/tool";
import {getStore} from "@/utils/storage";
export default {
  name: "Create",
  components:{
    Header,
    ImgUpload
  },
  data(){
    var checkCredit= (rule, value, callback) => {
      let reg = /[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
       if (!reg.test(value)) {
        callback(new Error('社会信用代码有误，请检查'));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback)=>{
      let reg_mobile =/^1[3456789]\d{9}$/
      let reg_phone =/^(0d{2,3})-?(d{7,8})$/
      if (!reg_mobile.test(value) && !reg_phone.test(value)){
        callback(new Error('请输入正确的手机或座机号码'));
      }else{
        callback();
      }
    }
    var checkAddress =(rule, value, callback) => {
      let reg = /^[0-9a-zA-Z\u4e00-\u9fa5 ]+$/g;
      if (!reg.test(value)) {
        callback(new Error('请输入正确的地址信息'));
      } else {
        callback();
      }
    }
      return {
        pageName: "创建企业",
        labelPosition: "left",
        labelPosition2: "top",
        carGmList: [
          {
            value: '1',
            label: '0-50'
          }, {
            value: '2',
            label: '51-100'
          }, {
            value: '3',
            label: '101-200'
          }, {
            value: '4',
            label: '200以上'
          }
        ], //车辆规模
        cityList: [], //城市三级列表
        ocrText:'',//文字识别内容
        formData: {
          enterpriseName: "", //企业名称
          enterpriseType: "出租车", //企业类型
          enterpriseLocation: "", //企业所在地
          enterpriseAddress: "", //企业详细地址
          vehicleScale: "", //车辆规模
          enterprisePhone: "", //企业联系方式
          socialCreditCode: "", //社会信用代码
          businessLicenseUrl: '', //营业执照
          proxyStatementUrl: '', //委托书
          transportationLicenseUrl: '', //道路运输许可证url
          taxiOperationLicenseUrl: '', //出租车经营许可证url
          logoUrl: '', //企业logo
          enterprisePhoto: [], //公司描述图
        },
        ruleCompany: {
          enterpriseName: [
            {required: true, message: '请输入企业名称', trigger: 'blur'},
          ],
          logoUrl: [
            {required: true, message: '公司logo是必须的', trigger: 'blur'},
          ],
          businessLicenseUrl: [
            {required: true, message: '营业执照是必须的', trigger: 'blur'},
          ],
          enterprisePhoto: [
            {required: true, message: '公司环境图是必须的', trigger: 'blur'},
          ],
          proxyStatementUrl: [
            {required: true, message: '授权委托书是必须的', trigger: 'blur'},
          ],
          enterpriseLocation: [
            {required: true, message: '请选择所在地', trigger: 'blur'},
          ],
          enterpriseType: [
            {required: true, message: '请选择企业类型', trigger: 'blur'},
          ],
          enterpriseAddress: [
            {required: true, message: '请选择所在地址', trigger: 'blur'},
            // {validator: checkAddress, trigger: 'blur'}
          ],
          vehicleScale: [
            {required: true, message: '请选择车辆规模', trigger: 'blur'},
          ],
          enterprisePhone: [
            {required: true, message: '请输入联系方式', trigger: 'blur'},
            {validator: checkPhone, trigger: 'blur'}
          ],
          socialCreditCode: [
            {required: true, message: '请输入社会信用代码', trigger: 'blur'},
            {validator: checkCredit, trigger: 'blur'}
          ]
        },
        viewImageUrl: "",
        showViewer: false
      }

  },
  created() {
    this.getCity()
  },
  methods:{
    toJoin(){
      this.$router.push({'name': '加入企业'})
    },
    getCity(){
      getTree().then(res=>{
        this.cityList= this.utils.fn(res.data.children)
      })
    },
    submitForm() {
      this.$refs["createForm"].validate((valid) => {
        if (valid) {
          let formData = this.formData
          formData.enterprisePhoto=formData.enterprisePhoto.join(',')
          addEnter(formData).then(res=>{
            if (res.code ==0){
              this.$baseMessage('企业创建成功','success')
              setTimeout(()=>{
                this.$router.push({name: '操作台'})
              }, 2000)
            }else{
              this.$baseMessage(res.code.message,'warning')
            }

            // if (res.code==0){
            //   this.$baseMessage('企业创建成功','success')
            // }
          })
        } else {
         // this.$baseMessage('表单信息有误','warning')
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["createForm"].resetFields();
    },
    handleChange(value) {
      this.formData.enterpriseLocation= value[2]
    },
    getImg1(e){
      this.formData.businessLicenseUrl=e
      let baseUrl =getStore('imgBase')
      this.toOcr(baseUrl+e,2)

    },
    getImg2(e){
      this.formData.proxyStatementUrl=e
    },
    getImg3(e){
      this.formData.transportationLicenseUrl=e
    },
    getImg4(e){
      this.formData.taxiOperationLicenseUrl=e
    },
    getImg5(e){
      this.formData.logoUrl=e
    },
    getImg6(e){
      this.formData.enterprisePhoto.push(e)
    },
    getImg7(e){
      this.formData.enterprisePhoto[1]=e
    },
    getImg8(e){
      this.formData.enterprisePhoto[2]=e
    },

    toOcr(img,type){
      let form ={
        cmd:type,
        url:img
      }
      ocr(form).then(res=>{
        let words = JSON.parse(res.data).words_result
        this.ocrText =res.data
        let formData={
          enterpriseName: words['单位名称']['words'], //企业名称
          enterpriseAddress: words['地址']['words'], //企业详细地址
          socialCreditCode: words['社会信用代码']['words'], //社会信用代码
        }
        this.formData = Object.assign({},this.formData, formData)
      })
    }
  }
}
</script>

<style  lang="less">
body{
  background: #FAFAFA;
  height: 100%;
}
.wrap{
  width: 100%;
  height: 100%;
  background: #FAFAFA;
}
.formCreate{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 60px;
  .formSubmit{
    width: 1540px;
    min-height: 550px;
    background: #FFFFFF;
    border-radius: 6px;
    margin: 0 auto;
    padding: 30px;
    .title{
      height: 22px;
      font-size: 16px;
      font-weight: 800;
      color: #333333;
      line-height: 22px;
      span{
        cursor: pointer;
      }
    }
    .formUpdate{
      .el-form{
        .el-form-item{
          .el-form-item__content{
            font-size: 16px;
            line-height: 42px;
            .el-input__inner{
              height: 42px;
              line-height: 60px;
              background: #FAFAFA;
              border-radius: 2px;
              border: none;
            }

          }
          .tips{
            height: 22px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 22px;
          }
        }
      }
    }
  }
  .loginAction{
    text-align: right;
  }
}
.el-select{
  width: 100%;
}
</style>
