<template>
  <router-link to="/">
    <div class="logo">
      <img src="../../../img/logo.png">
    </div>
  </router-link>
</template>

<script>
export default {
  name: "logo"
}
</script>

<style  lang="less">
.logo{
  height: 200px;
  text-align: center;
  line-height: 200px;
  img{
    width: 146px;
  }
}
</style>
