/**
 * @notice 工具类
 * @author hhq
 */
import http from "../http"
//图像识别
export const ocr = (params) => {
    return http.postRegister('/app/bd/ocr', params)
}
//注册前获取验证码
export const getBeforeCode = (params) => {
    return http.postRegister('/app/tool/before/sms?phone=' + params.phone, params)
}
//删除图片
export const deleteFile = (params) => {
    return http.postRegister('/app/tool/deleteFile', params)
}
//获取省市区
export const getTree = (params) => {
    return http.postRegister('/app/tool/getTree', params)
}
//注册后获取验证码
export const getCodeElse = (params) => {
    return http.postRegister('/app/tool/sms', params)
}
//上传图片
export const uploadFile = (params) => {
    return http.uploadFileRequest('/app/tool/uplodFile', params)
}

//forgotPass
export const forgotPass = (params) => {
    return http.postLogin(`/ad/app/auth/resetPwd?phone=${params.phone}&cmd=3&code=${params.code}&passWord=${params.passWord}`, params)
}
// export const forgotPass = (params) => {
//     return http.postLogin(`/ad/app/auth/resetPwd`,params)
// }
