<template>

    <el-menu :show-timeout="200" :default-active="$route.path" :collapse="isCollapse"
             mode="vertical" background-color="#0F1F3E" text-color="#AAAAAA" active-text-color="#FFFFFF"  router>
      <template v-for="(item,index) in routes" v-if="!item.hidden" >
        <!-- 一级菜单 -->
        <el-menu-item v-if="!item.leaf && !item.leafThree && item.children.length>0 && showRouter(item.permissions)===true" :index="item.path" >
          <template slot="title">
            <img :src="item.meta.showPath===activeId?item.icon:item.icon_dark" :alt="item.name" class="router-icon" >
            <span>{{item.name}}</span>
          </template>
        </el-menu-item>
        <!-- 二级菜单 -->
        <el-submenu :index="index+''" v-if="item.leaf">
          <template slot="title">
            <img :src="item.meta.showPath===activeId?item.icon:item.icon_dark" :alt="item.name" class="router-icon" >
            <span>{{item.name}}</span>
          </template>
          <el-menu-item v-for="child in item.children" :index="child.path" v-if="!child.hidden && showRouter(child.permissions)===true"  :key="child.path" >

            <span>{{child.name}}</span>
          </el-menu-item>
        </el-submenu>
        <!-- 三级菜单 -->
        <el-submenu :index="index+''" v-if="!item.leaf && item.leafThree">
          <template slot="title">

            <span>{{item.name}}</span>
          </template>

          <el-submenu v-for="child in item.children" :index="child.path" :key="child.path" v-if="child.leaf">
            <template slot="title">

              <span>{{child.name}}</span>
            </template>
            <el-menu-item v-for="childThree in child.children" :index="childThree.path" :key="childThree.path">

              <span>{{childThree.name}}</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-for="child in item.children" :index="child.path" v-if="!child.hidden && !child.leaf"  :key="child.path">

            <span>{{child.name}}</span>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>

</template>

<script>
import router from '@/router/index'
import {mapGetters} from "vuex";
import {getUserInfo} from "@/utils/api/own";

let hand;
export default {
  name: "SideBar",
  data(){
    return {
      isCollapse: false,
      activeId:""
    }
  },
  watch: {
    $route: {
      handler (val, oldval) {
        this.activeId= val.meta.showPath
      },
      // 深度观察监听
      deep: true
    }
  },
  computed:{
    routes() {
      return this.$router.options.routes
    },
  },
  created() {
    let now =this.$route
    this.activeId= now.meta.showPath
  },
  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  methods:{
    showRouter(permission){
      if (permission==='admin'){
        return true
      }else{
        let result = this.$store.state.permissions.findIndex(item => item=== permission)
        // let result = this.permissions.findIndex(item => item=== permission)
        if (result!=-1){
          return true
        }else{
          return false
        }
      }
    },

  },

}
</script>

<style  lang="less">

</style>
