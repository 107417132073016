<template>
  <div class="wrap1">
    <div class="logo1">
      <img src="../../img/logo.png" alt="利行租赁端">
    </div>
    <div class="slogan">
      <p>车辆租售上<span class="large">利行</span></p>
      <p><span class="large">海量</span>司机享不停</p>
    </div>
    <div class="login">
      <div class="loginForm">
        <h1 class="title">欢迎登录！</h1>
        <el-tabs v-model="activeName" @tab-click="changeWay" class="changeWay">
          <el-tab-pane label="短信登录" name="1"></el-tab-pane>
          <el-tab-pane label="账号登录" name="2"></el-tab-pane>
        </el-tabs>
        <div class="formUpdate">
          <el-form ref="loginForm" :model="formData">
            <el-form-item v-if="activeName==1">
              <el-input v-model="formData.mobile" type="text" placeholder="请输入手机号码" maxlength="11"></el-input>
              <el-button  v-if="isSend==0" @click="getCode">获取验证码</el-button>
              <el-button v-if="isSend==1">{{ count }}s</el-button>
              <el-button  v-if="isSend==2" @click="getCode">重新获取</el-button>
            </el-form-item>
            <el-form-item v-if="activeName==1">
              <el-input v-model="formData.code" type="text" placeholder="请输入验证码" maxlength="6"></el-input>
            </el-form-item>
            <el-form-item v-if="activeName==2">
              <el-input v-model="formData.username" type="text" placeholder="请输入账号">
              </el-input>
            </el-form-item>
            <el-form-item v-if="activeName==2">
              <el-input v-model="formData.password" type="password" placeholder="请输入密码" show-password maxlength="20">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="actions clearfix">
          <router-link to='/register'>
            <div class="action fl create">没有账号?点此创建</div>
          </router-link>
          <router-link to='/forget'>
            <div class="action fr forget">忘记密码？</div>
          </router-link>
        </div>
        <div class="loginAction">
          <el-button type="primary action" @click="handleLogin">登录</el-button>
        </div>
        <div class="agree clearfix">
          <el-checkbox v-model="isAgree" class="fl"></el-checkbox>
          <p class="fl">勾选表示您已同意<span>
            <a href="https://cardriver.lixingpt.com/mobileApplet/post?id=14" target="view_window">《平台服务协议》</a>
          </span>和<span>
            <a href="https://cardriver.lixingpt.com/mobileApplet/post?id=9" target="view_window">《隐私政策》</a>
          </span></p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {toLogin} from "@/utils/api/login";
import {getStore, setStore} from '@/utils/storage'
import {getImgUrl} from '@/utils/api/dict'
import {getUserInfo} from "@/utils/api/own";
import {getBeforeCode} from "@/utils/api/tool";

export default {
  name: "Login",
  data() {
    return {
      activeName: "1",
      isSend: 0, // 0验证码未发送，1发送倒计时，3再次发送
      count: 60, //60秒倒计时
      isAgree: false,
      formData: {
        mobile: "",
        username: "",
        password: "",
        code: "",
        grant_type: 'app'
      }
    }
  },
  methods: {
    changeWay() {
      if (this.activeName == 2) {
        this.formData.grant_type = "password"
        this.formData.scope = "server"
      } else {
        this.formData.grant_type = "app"
      }
    },
    //登录
    handleLogin() {
      localStorage.clear()
      if (this.isAgree == false) {
        this.$baseMessage('请同意第三方协议', 'warning')
        return false
      }
      if (!this.formData.mobile && !this.formData.username) {
        this.$message.error("手机号或账号不能为空")
        return false
      }else{
        if (this.activeName=='1'){
          if (this.formData.mobile && this.formData.mobile.length !== 11) {
            this.$message.error("请输入正确手机号")
            return false
          }
        }else{
          if(!this.formData.username && !this.formData.password){
            this.$message.error("账号和密码不能为空")
            return false
          }
        }
      }
      this.$refs.loginForm.validate((valid) => {
        let param = ""
        for (var p in this.formData) {
          if (this.formData[p] != '') {
            param = param + p + '=' + this.formData[p] + "&"
          }
        }
        param = param.slice(0, param.length - 1)
        getImgUrl().then(res => {
          if (res.code === 0) {
            setStore('imgBase', res.data)
          }
        })
        if (valid) {
          toLogin(param).then(res => {
            if (res.code === 1) {
              this.$message.error(res.msg)
            } else {
              setStore('access_token', res.access_token)
              if (res.user_info.companyId != null) {
                setStore('companyId', res.user_info.companyId)
                this.$store.dispatch('getUserStoreInfo')
              } else {
                this.$router.push({name: "完善信息"})
                return false
              }
              this.$router.push({name: "操作台"});
            }
          })
        }
      })
    },
    getCode() {
      if (!this.formData.mobile) {
        this.$message.error("手机号不能为空")
        return false
      }
      if (this.formData.mobile.length !== 11) {
        this.$message.error("请输入正确手机号")
        return false
      }
      let data = {
        phone: this.formData.mobile
      }
      getBeforeCode(data).then(res => {
        if (res.code === 0) {
          this.isSend = 1
          this.$message.success("验证码发送成功")
          let timeId = setInterval(() => {
            this.count--
            if (this.count === 0) {
              this.isSend = 0
              window.clearInterval(timeId)
            }
          }, 1000)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

  }
}
</script>

<style  lang="less">
.wrap1 {
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("../../img/background.png");
  background-size: cover;
  //object-fit: fill;
  background-repeat: no-repeat;

  .logo1 {
    position: relative;
    top: 77px;
    left: 56px;

    img {
      width: 146px;
    }
  }
}

.slogan {
  position: relative;
  top: 351px;
  left: 212px;

  p {
    height: 67px;
    font-size: 36px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 50px;
    letter-spacing: 2px;

    .large {
      font-size: 48px;
    }
  }
}

.login {
  width: 529px;
  min-height: 500px;
  background: rgba(244, 255, 255, .8);
  border-radius: 8px;
  position: absolute;
  right: 148px;
  top: 50%;
  transform: translateY(-50%);

  .loginForm {
    width: 469px;
    margin: 0 auto;
    padding: 60px 0;

    .title {
      height: 59px;
      font-size: 42px;
      font-weight: 600;
      color: #333333;
      line-height: 59px;
      letter-spacing: 5px;
    }
    .changeWay{
      padding-top: 110px;
      padding-bottom: 48px;
      .el-tabs__nav-wrap {
        &:after{
          background-color: initial;
        }
        .el-tabs__nav-scroll{
          width: 100%;
          padding-left: 0;
          .el-tabs__nav{
            width: 100%;
            text-align: center;
            .el-tabs__item{
              width: 50%;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              line-height: 22px;
              letter-spacing: 1px;
            }
            .el-tabs__item.is-active{
              font-size: 24px;
              font-weight: 500;
              color: #3C6CFC;
              line-height: 33px;
            }
          }
        }
      }
    }
    .formUpdate {
      .el-form {
        .el-form-item {
          .el-form-item__content {
            font-size: 16px;
            line-height: 60px;
            .el-input{

              .el-input__inner {
                height: 60px;
                line-height: 60px;
                background: #FFFFFF;
                border-radius: 2px;

              }
            }
          }
          .el-button{
            background-color: #FFFFFF;
            position: absolute;
            right: 1px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            &:before {
              content: '';
              width: 1px;
              height: 60%;
              background-color: #AAAAAA;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .actions {
      padding: 0 20px;
      height: 17px;

      a {
        font-size: 12px;
        font-weight: 400;
        color: #3C6CFC;
        line-height: 17px;
      }

    }

    .loginAction {
      margin: 100px 0 20px;

      button {
        width: 100%;
        height: 64px;
        background: #3C6CFC;
        border-radius: 2px;

        span {
          font-size: 24px;
          font-weight: 500;
          color: #FFFFFF;
          letter-spacing: 1px;
        }
      }
    }

    .agree {
      display: flex;
      align-items: center;
      padding-left: 20px;

      p {
        padding-left: 10px;
        margin: 0;
        height: 24px;
        font-size: 12px;
        font-weight: 400;
        color: #AAAAAA;
        line-height: 24px;

        span {
          color: #3C6CFC;
        }
      }
    }
  }
}

//.el-tabs__nav-scroll{
//  padding: 0;
//}
//.el-tabs__nav{
//  width: 100%;
//}
//.el-tabs__item{
//  font-size: 16px;
//  font-weight: 400;
//  color: #333333;
//  letter-spacing: 1px;
//  text-align: center;
//  width: 50%;
//}
//.el-tabs__item.is-active{
//  font-size: 24px;
//  font-weight: 500;
//  color: #3C6CFC;
//}
//.el-tabs__nav-wrap {
//  &:after{
//    display: none;
//  }
//}
</style>
