/**
 * @notice 企业相关接口
 * @author hhq
 */
import http from "../http"

/*企业与员工*/
//加入企业
export const addEnterPrise =(params)=>{
    return http.postRegister('/app/tInviteCode/addEnterPrise',params)
}
//企业加入人员
export const addUserInfo =(params)=>{
    return http.postRegister('/app/tInviteCode/addUserInfo',params)
}
//解绑企业或员工
export const deleteUser =(params)=>{
    return http.postRegister('/app/tInviteCode/delete',params)
}
//查看员工详情
export const getMemberDetail =(params)=>{
    return http.postRegister('/app/tInviteCode/detail',params)
}
//更新用户信息
export const editUser =(params)=>{
    return http.postRegister('/app/tInviteCode/editUser',params)
}
//获取人员列表
export const getMList =(params)=>{
    return http.postRegister('/app/tInviteCode/list',params)
}
/*企业与品牌*/
//添加品牌
export const addBrand =(params)=>{
    return http.postRegister('/app/enter/brand/add',params)
}
//删除品牌
export const delBrand =(params)=>{
    return http.postRegister('/app/enter/brand/delete',params)
}
//获取品牌列表
export const getBrands =(params)=>{
    return http.postRegister('/app/enter/brand/page',params)
}
/*企业管理*/
//添加企业
export const addEnter =(params)=>{
    return http.postRegister('/app/enter/addEnter',params)
}
//获取企业详情
export const getCompanyDetail =(params)=>{
    return http.postRegister('/app/enter/detail',params)
}
//获取企业列表
export const getCompanyList =(params)=>{
    return http.postRegister('/app/enter/page',params)
}
//修改企业信息
export const updateCompany =(params)=>{
    return http.postRegister('/app/enter/updateEnter',params)
}
