<template>
  <div class="wrap1">
    <div class="logo">
      <img src="../../img/logo.png" alt="利行租赁端">
    </div>
    <div class="slogan">
      <p>车辆租售上<span class="large">利行</span></p>
      <p><span class="large">海量</span>司机享不停</p>
    </div>
    <div class="login">
      <div class="loginForm">
        <h1 class="title">欢迎注册！</h1>
        <div class="has">
          <p>已有账号？
            <router-link to='/login'>
              <span>登录</span>
            </router-link></p>
        </div>
        <div class="formRegister">
          <el-form ref="loginForm" :model="formData" :rules="ruleUser">
            <el-form-item prop="phone">
              <el-input v-model="formData.phone" type="text" placeholder="请输入手机号码" maxlength="11"></el-input>
              <el-button  v-if="isSend==0" @click="getCode">获取验证码</el-button>
              <el-button  v-if="isSend==1">{{ count }}s</el-button>
              <el-button  v-if="isSend==2" @click="getCode">重新获取</el-button>
            </el-form-item>
            <el-form-item prop="code">
              <el-input v-model="formData.code" type="text" placeholder="请输入验证码" maxlength="6"></el-input>
            </el-form-item>
            <el-form-item prop="username">
              <el-input v-model="formData.username" type="text" placeholder="请输入账号" maxlength="32"></el-input>
            </el-form-item>
            <el-form-item prop="passWord" >
              <el-input v-model="formData.passWord" type="password" placeholder="请输入密码" show-password maxlength="18" minlength="8">
              </el-input>
              <p class="remind">
                <img src="../../img/remind.png">
                长度为8-18位字符，大小写英文字母、数字，不允许有特殊字符、空格、中文字符
              </p>
            </el-form-item>
          </el-form>
        </div>
        <div class="loginAction">
          <el-button type="primary action" @click="submit" v-if="isAgree==1">注册</el-button>
          <el-button type="default action" v-else>注册</el-button>
        </div>
        <div class="agree clearfix">
          <el-checkbox v-model="isAgree" class="fl"></el-checkbox>
<!--          <p class="fl">勾选表示您已同意<span>《平台服务协议》</span>和<span>《隐私政策》</span></p>-->
          <p class="fl">勾选表示您已同意<span>
            <a href="https://cardriver.lixingpt.com/mobileApplet/post?id=14" target="view_window">《平台服务协议》</a>
          </span>和<span>
            <a href="https://cardriver.lixingpt.com/mobileApplet/post?id=9" target="view_window">《隐私政策》</a>
          </span></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRegister } from "@/utils/api/login";
import {getBeforeCode} from "@/utils/api/tool";
export default {
  name: "Register",
  data(){
    var checkPhone = (rule, value, callback)=>{
      let reg_mobile =/^1[3456789]\d{9}$/
      if (!reg_mobile.test(value) ){
        callback(new Error('请输入正确的手机或座机号码'));
      }else{
        callback();
      }
    }
    var checkPass =  (rule, value, callback)=>{
      let reg_pass=/^[A-Za-z0-9]{8,20}$/
      if (!reg_pass.test(value) ){
        callback(new Error('请输入8-20位的数字字母（含大小写）为密码'));
      }else{
        callback();
      }
    }
    return{
      activeName:"1",
      isSend:0, // 0验证码未发送，1发送倒计时，3再次发送
      count:60, //60秒倒计时
      isAgree:0,
      formData:{
        phone: "",
        username:"",
        passWord: "",
        code: "",
      },
      ruleUser:{
        phone:[
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        passWord:[
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: checkPass, trigger: 'blur' }
        ],
        username:[
          { required: true, message: '请输入账号', trigger: 'blur' },
          // { validator: checkUsername, trigger: 'blur' }
        ],
        code:[
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      }
    }
  },
  methods:{
    //注册
    submit(){
      if(!this.formData.phone){
        this.$message.error("手机号不能为空")
        return false
      }
      if(this.formData.phone.length !== 11){
        this.$message.error("请输入正确手机号")
        return false
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          toRegister(this.formData).then(res=>{
            if(res.code===0){
              this.$baseMessage('注册成功','success')
              this.$router.push({path: '/login'})
            }else{
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$message.error("检查输入信息")
        }
      })

    },
    getCode(){
      if(!this.formData.phone){
        this.$message.error("手机号不能为空")
        return false
      }
      if(this.formData.phone.length !== 11){
        this.$message.error("请输入正确手机号")
        return false
      }
      let data = {
        phone: this.formData.phone
      }
      getBeforeCode(data).then(res => {
        if(res.code === 0 ){
          this.isSend = 1
          this.$message.success("验证码发送成功")
          let timeId = setInterval(()=>{
            this.count --
            if(this.count === 0){
              this.isSend = 0
              window.clearInterval(timeId)
            }
          },1000)
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  },
  //   getAllDrugInfo () {
  //     getAllDrugInfo({
  //       drugName: ''  // 需要传递的参数
  //     }).then(res => {
  //       if (res.code === 200) {
  //         //处理你要的数据
  //         console.log(res.data)
  //       })
  //     }
  //   })
  //
  // }
}
</script>
<style  lang="less">
.wrap1{
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: url("../../img/background.png");
  background-size: cover;
  //object-fit: fill;
  background-repeat: no-repeat;
  .logo{
    position: relative;
    top: 77px;
    left: 56px;
    width: 10%;
    img{
      width: 146px;
    }
  }
}

.slogan{
  position: relative;
  top: 351px;
  left: 212px;
  p{
    height: 67px;
    font-size: 36px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 50px;
    letter-spacing: 2px;
    .large{
      font-size: 48px;
    }
  }
}
.login{
  width: 529px;
  min-height: 500px;
  background: rgba(244,255,255,.8);
  border-radius: 8px;
  position: absolute;
  right: 148px;
  top: 50%;
  transform: translateY(-50%);
  .loginForm{
    width: 469px;
    margin: 0 auto;
    padding: 60px 0;
    .title{
      height: 59px;
      font-size: 42px;
      font-weight: 600;
      color: #333333;
      line-height: 59px;
      letter-spacing: 5px;
    }
    .has{
      padding: 12px 0 88px;
      p{
        height: 25px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
        letter-spacing: 1px;
        a{
          height: 25px;
          font-size: 18px;
          font-weight: 400;
          color: #3C6CFC;
          line-height: 25px;
          letter-spacing: 1px;
        }
      }
    }
    .formRegister{
      .el-form{
        .el-form-item {
          .el-form-item__content {
            font-size: 16px;
            line-height: 60px;
            .el-input{

              .el-input__inner {
                height: 60px;
                line-height: 60px;
                background: #FFFFFF;
                border-radius: 2px;

              }
            }
          }
          .el-button{
            background-color: #FFFFFF;
            position: absolute;
            right: 1px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            &:before {
              content: '';
              width: 1px;
              height: 60%;
              background-color: #AAAAAA;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
    .actions{
      padding: 0 20px;
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #3C6CFC;
      line-height: 17px;
    }
    .loginAction{
      margin: 100px 0 20px;
      button{
        width: 100%;
        height: 64px;

        border-radius: 2px;
        span{
          font-size: 24px;
          font-weight: 500;

          letter-spacing: 1px;
        }
      }
      .el-button--primary{
        background: #3C6CFC;
        span{
          color: #FFFFFF;
        }
      }
    }
    .agree{
      display: flex;
      align-items: center;
      padding-left: 20px;
      p{
        padding-left: 10px;
        margin: 0;
        height: 24px;
        font-size: 12px;
        font-weight: 400;
        color: #AAAAAA;
        line-height: 24px;
        span{
          color: #3C6CFC;
        }
      }
    }
  }
}
.remind{
  height: 14px;
  font-size: 10px;
  font-weight: 400;
  color: #3C6CFC;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  letter-spacing: -0.5px;
  padding-top: 10px;
  img{
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
}
</style>
