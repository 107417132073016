<template>
  <div class="header1">
    <div class="logo1">
      <img src="../../../img/logo2.png" alt="利行租赁端">
    </div>
    <div class="pageTitle">
      {{ title}}
    </div>
  </div>
</template>

<script>
import {getImgUrl} from "@/utils/api/dict";
import {setStore} from "@/utils/storage";
import {getJurisdiction} from "@/utils/api/own";
export default {
  name: "Header",
  props:{
    title: {
      type: String,
      default: () => ''
    }
  },
  created() {
    this.getUrl()
    // this.getUserDetail()
  },
  methods:{
    getUrl(){
      getImgUrl().then(res=>{
        setStore('imgBase',res.data)
      })
    },

  }
}
</script>

<style  lang="less">
.header1{
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 122px;
  padding-left: 53px;
  .logo1{
    width: 198px;
    position: relative;
    &:after{
      content: '';
      width: 1px;
      height: 22px;
      background-color: #AAAAAA;
      position: absolute;
      right: 0;
      bottom: 1%;
      //transform: translateY(-50%);
    }
    img{
      width: 146px;
    }
  }
  .pageTitle{
    width: 95px;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    text-align: right;
  }
}
</style>
