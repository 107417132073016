<template>
  <div id="wrap">
    <Header :title="pageTitle"></Header>
    <div class="formForget">
      <div class="formSubmit">
        <h1 class="title">设置新密码</h1>
       <p class="go"> 前往
         <router-link to='/login'>
        <span class="">登录</span>
        </router-link>
         <router-link to='/register'>
           <span class="">注册</span>
         </router-link>
       </p>
        <div class="formUpdate">
          <el-form ref="loginForm" :model="formData">
            <el-form-item prop="phone">
              <el-input v-model="formData.phone" type="text" placeholder="请输入手机号码" maxlength="11">
                <el-button slot="append" v-if="isSend==0" @click="getCode">获取验证码</el-button>
                <el-button slot="append" v-if="isSend==1">{{ count }}s</el-button>
                <el-button slot="append" v-if="isSend==2" @click="getCode">重新获取</el-button>
              </el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input v-model="formData.code" type="text" placeholder="请输入验证码" maxlength="6"></el-input>
            </el-form-item>

            <el-form-item prop="password">
              <el-input v-model="formData.passWord" type="password" placeholder="请输入密码" show-password maxlength="20">
              </el-input>
              <p class="remind">
                <img src="../../img/remind.png">
                长度为8-18位字符，大小写英文字母、数字，不允许有特殊字符、空格、中文字符
              </p>
            </el-form-item>
          </el-form>

        </div>
        <div class="loginAction">
          <el-button type="primary action" @click="submit">确认新密码</el-button>
        </div>
        <div class="agree clearfix">
          <el-checkbox v-model="isAgree" class="fl"></el-checkbox>
<!--          <p class="fl">勾选表示您已同意<span>《平台服务协议》</span>和<span>《隐私政策》</span></p>-->
          <p class="fl">勾选表示您已同意<span>
            <a href="https://cardriver.lixingpt.com/mobileApplet/post?id=14"  target="view_window">《平台服务协议》</a>
          </span>和<span>
            <a href="https://cardriver.lixingpt.com/mobileApplet/post?id=9" target="view_window">《隐私政策》</a>
          </span></p>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Header from "@/views/login/components/Header";
import {updateDetail} from "@/utils/api/own";
import { getBeforeCode, forgotPass } from '@/utils/api/tool'

export default {
  name: "Forget",
  components:{
    Header
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      let reg_mobile = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
      let reg_phone = /^(0d{2,3})-?(d{7,8})$/;
      if (!reg_mobile.test(value)) {
        callback(new Error("请输入正确的手机或座机号码"));
      } else {
        callback();
      }
      if (!reg_phone.test(value)) {
        callback(new Error("请输入正确的手机或座机号码"));
      } else {
        callback();
      }
    };
    var checkPass =  (rule, value, callback)=>{
      let reg_pass=/^[A-Za-z0-9]{6,20}$/
      if (!reg_pass.test(value) ){
        callback(new Error('请输入8-18位的数字字母（含大小写）为密码'));
      }else{
        callback();
      }
    }
    return{
      pageTitle:"忘记密码",
      isSend:0, // 0验证码未发送，1发送倒计时，3再次发送
      count:60, //60秒倒计时
      isAgree:false,
      formData:{
        phone:"",
        code:"",
        passWord:"",
        cmd:"3"
      },
      ruleForget:{
        phone:[
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        code:[
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        password:[
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: checkPass, trigger: 'blur' }
        ],
      }
    }
  },
  methods:{
    submit(){
      if(this.isAgree === false){
        this.$message.error("未同意平台服务协议与隐私协议")
        return false
      }
      if(!this.formData.phone){
        this.$message.error("手机号不能为空")
        return false
      }
      if(this.formData.phone.length !== 11){
        this.$message.error("请输入正确手机号")
        return false
      }
      this.$refs['loginForm'].validate((valid)=>{
        if (valid){
          forgotPass(this.formData).then(res=>{
            if(res.code === 0){
              this.$baseMessage('修改成功，请重新登陆','success')
              setTimeout(() => {
                this.$router.push({name: '登陆'})
              }, 2000)
            }else{
              this.$baseMessage(res.msg,'error')
            }
          })
        }else{
          this.$baseMessage('请检查信息','warning')
        }
      })
    },
    getCode(){
      if(!this.formData.phone){
        this.$message.error("手机号不能为空")
        return false
      }
      if(this.formData.phone.length !== 11){
        this.$message.error("请输入正确手机号")
        return false
      }
      let data = {
        phone: this.formData.phone
      }
      getBeforeCode(data).then(res => {
        if(res.code === 0 ){
          this.isSend = 1
          this.$message.success("验证码发送成功")
          let timeId = setInterval(()=>{
            this.count --
            if(this.count === 0){
              this.isSend = 0
              window.clearInterval(timeId)
            }
          },1000)
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style  lang="less">
body{
  background: #FAFAFA;
  height: 100%;
}
.wrap{
  width: 100%;
  height: 100%;
  background: #FAFAFA;
}

.formForget{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 60px;
  .formSubmit{
    width: 470px;
    margin: 0 auto;
    .title{
      height: 59px;
      font-size: 42px;
      font-weight: 600;
      color: #333333;
      line-height: 59px;
      letter-spacing: 5px;
    }
    .go{
      height: 25px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 25px;
      letter-spacing: 1px;
      padding: 12px 0 43px;
      a{
        color: #3C6CFC;
        padding-left: 6px;
      }
    }
    .formUpdate{
      .el-form{
        .el-form-item{
          .el-form-item__content{
            font-size: 16px;
            line-height: 60px;
            .el-input__inner{
              height: 60px;
              line-height: 60px;
              background: #FFFFFF;
              border-radius: 2px;
              border: none;
            }
            .el-input-group__append{
              background-color: #FFFFFF;
              border: none;
              position: relative;
              height: 60px;
              line-height: 60px;
              &:before{
                content: '';
                width: 1px;
                height: 60%;
                background-color: #AAAAAA;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }

        }
      }
    }
    .loginAction{
      margin: 100px 0 20px;
      button{
        width: 100%;
        height: 64px;
        background: #3C6CFC;
        border-radius: 2px;
        span{
          font-size: 24px;
          font-weight: 500;
          color: #FFFFFF;
          letter-spacing: 1px;
        }
      }
    }
    .remind{
      height: 14px;
      font-size: 10px;
      font-weight: 400;
      color: #3C6CFC;
      line-height: 14px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      letter-spacing: -0.5px;
      padding-top: 20px;
      img{
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
    }
    .agree{
      padding-left: 20px;
      p{
        padding-left: 10px;
        margin: 0;
        height: 24px;
        font-size: 12px;
        font-weight: 400;
        color: #AAAAAA;
        line-height: 24px;
        span{
          color: #3C6CFC;
        }
      }
    }
  }

}

</style>
