import http from "../http"
import config from "@/utils/config";
//注册
export const toRegister =(params)=>{
    return http.postRegister('/app/auth/register',params)
}
//登陆
export const toLogin = (params)=>{
    return http.postLogin(config.baseUrl.replace("/webapp",'')+'/auth/oauth/token?'+params,{})
}
//获取协议/隐私
export const displayContent=(params)=>{
    return http.postRegister('/app/auth/about/displayContent',params)
}
//忘记密码
export const resetPwd=(params)=>{
    return http.postRegister('/app/auth/resetPwd',params)
}
//退出登陆
export const loginOut=(params)=>{
    return http.deleteRequest(config.baseUrl.replace("/webapp",'')+'/auth/token/logout',{})
}
