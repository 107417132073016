<template>
  <div id="wrap">
    <Header :title="pageTitle"></Header>
    <div class="formIndex">
      <div class="formSubmit">
        <h1 class="title">完善个人信息</h1>

        <div class="formUpdate">
          <el-form ref="loginForm" :model="formData" :rules="ruleUser">
            <el-form-item prop="realName">
              <el-input v-model="formData.realName" type="text" placeholder="请输入真实姓名"></el-input>
            </el-form-item>
            <el-form-item prop="carNum">
              <el-input v-model="formData.carNum" type="text" placeholder="请输入身份证号码"></el-input>
            </el-form-item>
            <el-form-item prop="workAddress">
<!--              <el-input v-model="formData.address" type="text" placeholder="请选择工作地点" > </el-input>-->
                <el-cascader
                    v-model="formData.workAddress"
                    :options="cityList"
                    placeholder="请选择工作地点"
                    :props="{ value: 'id',label:'text'}"
                    @change="handleChange">
                </el-cascader>

            </el-form-item>
          </el-form>
        </div>
        <div class="loginAction">
          <el-button type="primary action" @click="submit">下一步</el-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Header from "@/views/login/components/Header";
import { updateDetail } from "@/utils/api/own";
import { getTree } from "@/utils/api/tool";
export default {
  name: "Info",
  components:{
    Header
  },
  data(){
    const verifyID = (idcode)=>{
      // 加权因子
      var weight_factor = [7,9,10,5,8,4,2,1,6,3,7,9,10,5,8,4,2];
      // 校验码
      var check_code = ['1', '0', 'X' , '9', '8', '7', '6', '5', '4', '3', '2'];

      var code = idcode + "";
      var last = idcode[17];//最后一位

      var seventeen = code.substring(0,17);

      // ISO 7064:1983.MOD 11-2
      // 判断最后一位校验码是否正确
      var arr = seventeen.split("");
      var len = arr.length;
      var num = 0;
      for(var i = 0; i < len; i++){
        num = num + arr[i] * weight_factor[i];
      }
      // 获取余数
      var resisue = num%11;
      var last_no = check_code[resisue];
      var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

      // 判断格式是否正确
      var format = idcard_patter.test(idcode);

      // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
      return last === last_no & format ? true : false;
    }
    var checkIdCard= (rule, value, callback)=>{

      if (!verifyID(value)){
        callback(new Error('请输入正确的身份证'));
      }else{
        callback();
      }
    }
    var checkName = (rule, value, callback) => {
      let reg = /^[a-zA-Z\u4e00-\u9fa5]+$/g;
      if(value.length>20){
        callback(new Error('输入的姓名过长'));
      }else if (!reg.test(value)) {
        callback(new Error('请输入中文或英文'));
      } else {
        callback();
      }
    };
    return{
      pageTitle:"完善信息",
      formData:{
        realName:"",
        carNum:"",
        workAddress:"",
        cmd:"1"
      },
      cityList:[],
      ruleUser:{
        realName:[
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { validator: checkName, trigger: ['blur', 'change'] }
        ],
        carNum:[
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
          { validator: checkIdCard, trigger: ['blur', 'change'] }
        ],
        workAddress:[
          { required: true, message: '请输入工作地点', trigger: 'blur' },
        ],
      }
    }
  },
  created() {
    this.getCity()
  },
  methods:{
    getCity(){
      getTree().then(res=>{
        // console.log(res)
        this.cityList= this.utils.fn(res.data.children)
      })
    },
    handleChange(value) {
      this.formData.workAddress= value[2]
    },
    submit(){
      this.$refs['loginForm'].validate(async (valid)=>{

        if (valid){
          updateDetail(this.formData).then(res=>{
            if(res.code === 0){
              this.$router.push({name: '加入企业'})
            }
          })
        }
      })
    }
  }
}
</script>

<style  lang="less">
body{
  background: #FAFAFA;
  height: 100%;
}
.wrap{
  width: 100%;
  height: 100%;
  background: #FAFAFA;
}

.formIndex{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 60px;
  .formSubmit{
    width: 470px;
    margin: 0 auto;
    .title{
      height: 59px;
      font-size: 42px;
      font-weight: 600;
      color: #333333;
      line-height: 59px;
      letter-spacing: 5px;
      padding-bottom: 80px;
    }
    .formUpdate{
      .el-form{
        .el-form-item{
          .el-form-item__content{
            font-size: 16px;
            line-height: 60px;
            .el-input__inner{
              height: 60px;
              line-height: 60px;
              background: #FFFFFF;
              border-radius: 2px;
              border: none;
            }
            .el-input-group__append{
              background-color: #FFFFFF;
              border: none;
              position: relative;
              height: 60px;
              line-height: 60px;
              &:before{
                content: '';
                width: 1px;
                height: 60%;
                background-color: #AAAAAA;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }

        }
      }
    }
    .loginAction{
      margin: 100px 0 20px;
      button{
        width: 100%;
        height: 64px;
        background: #3C6CFC;
        border-radius: 2px;
        span{
          font-size: 24px;
          font-weight: 500;
          color: #FFFFFF;
          letter-spacing: 1px;
        }
      }
    }

  }

}

</style>
