<template>
  <div>
    <el-upload
        class="avatar-uploader"
        action="#"
        :before-upload="beforeUpload"
        :on-preview="handlePictureCardPreview"
        :accept="accept"
        :http-request="httpRequestMain"
        :fileList="fileList"
        :show-file-list="false">

      <div class="img-box" v-if="imgUrl">
        <img :src="imgUrl" :alt="imgName">
        <p>重新上传</p>
      </div>
      <div class="explain" v-else>
        <i  class="el-icon-plus avatar-uploader-icon"></i>
        <p class="words">上传{{imgName}}</p>
      </div>
    </el-upload>
    <p class="tips">只支持.jpg/.png格式</p>
  </div>

</template>

<script>
import {getStore} from "@/utils/storage";
import url from "@/utils/config";

export default {
  name: "ImgUpload",
  props:{
    value: {
      type: String,
      default: "",
    },
    imgName:{
      type: String,
      default: "",
    },
    imgType:{
      type:String,
      default:"0"
    }
  },
  data(){
    return{
      accept:"image/jpeg, image/png",
      size:4 * 1024,
      header:{
        'Content-Type': "multipart/form-data",
        'authorization':"Bearer "+getStore('access_token')
      },
      imgUrl:"",
      fileList: [],
    }
  },
  watch: {
    // value(val) {
    //   this.watchValue();
    // },
  },
  methods:{
    watchValue(){
      let imgBase = getStore('imgBase')
      let valueArray = this.value ? this.value.split(",") : [];
      this.imgUrl = valueArray.map((item) => {
        if (item){
          return imgBase + item;
        }
        return false
      });
      // this.update();
    },
    beforeUpload(file){
      const fileMax = file.size / 1024 < this.size;
      if (!fileMax) {
        let measure = this.utils.renderSize(this.size / 1024)
        this.$baseMessage('上传图片大小不能超过'+measure,'warning')
      }
      return !!fileMax;
    },
    handlePictureCardPreview(file){
      this.viewImageUrl = [file.url];
      this.showViewer = true;
    },
    httpRequestMain(f){
      // console.log(f.file)
      // let param = new FormData(); //创建form对象
      // param.append("file", f.file); //通过append向form对象添加数据
      // let config = {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     'authorization':"Bearer "+getStore('access_token')
      //   },
      //
      // }; //添加请求头
      // this.$axios.post("http://192.168.1.18:9999/webapp/app/tool/uplodFile", param, config)
      //     .then(res=>{
      //       this.$emit("input",res.data.data.url)
      //     }).catch(()=>{
      //
      // })



      let param = new FormData(); //创建form对象
      let that = this;
      param.append("file", f.file); //通过append向form对象添加数据
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          'authorization':"Bearer "+getStore('access_token')
        },

      }; //添加请求头
      let imgBase = getStore('imgBase')
      this.$axios.post(url.baseUrl+"/app/tool/uplodFile", param, config)
          .then((res) => {
            // that.imgurl = response.data.data.url;
            this.imgUrl= imgBase+res.data.data.url
            this.$emit("input",res.data.data.url)
            // f.onSuccess(response);
            // this.update();
          })
          .catch(() => {
            // f.onError();
          });
      return
    },
  }
}
</script>

<style  lang="less">
.img-box{
  width: 150px;
  height: 120px;
  &:hover{
    cursor: pointer;
  }
  img{
    width: 150px;
    height: 120px;
    .words{
      border: none;
    }
  }
  p{
    width: 150px;
    height: 25px;
    background: rgba(0,0,0,.7);
    border-radius: 0px 0px 2px 2px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    position: absolute;
    bottom: 0;
  }
}

</style>
